
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 19th of July 2021
   *
   * Summary: AccountsDashboard component
   */
  import { Component, Vue } from 'vue-property-decorator';
  import AccountsDashboard from '@/components/dashboards/accounts-dashboard/AccountsDashboard.vue';

  @Component({
    components: {
      AccountsDashboard
    }
  })
  export default class AccountsDashboardContainer extends Vue {}

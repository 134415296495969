
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 19th of July 2021
   *
   * Summary: Input with left icon inside
   */
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { ValidationProvider } from 'vee-validate';

  @Component({
    components: {
      ValidationProvider
    }
  })
  export default class InputWithIcon extends Vue {
    @Prop({ default: '', type: String })
    readonly icon: string;

    @Prop({ required: true })
    readonly value: unknown;

    @Prop({ required: true, type: String })
    readonly id: string;

    // Default number obtained from: https://www.w3schools.com/tags/att_input_maxlength.asp
    @Prop({ default: 524288, type: Number })
    readonly maxLength: number;

    @Prop({ default: 'text', type: String })
    readonly type: string;

    @Prop({ default: '', type: String })
    readonly placeHolder: string;

    @Prop({ default: false, type: Boolean })
    readonly isLazy: boolean;

    @Prop({ default: '', type: String })
    readonly error: string;

    @Prop({ default: false, type: Boolean })
    readonly readOnly: boolean;

    @Prop({ default: '', type: String })
    readonly rules: string;

    @Prop({ default: '', type: String })
    readonly customErrorMessage: string;

    @Prop({ required: false, default: false, type: Boolean })
    readonly autofocus: boolean;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public getState(validationProperty: any): boolean {
      if (this.rules === '' || validationProperty === null) {
        return null;
      }
      const { dirty, validated, valid, required } = validationProperty;
      return dirty && validated && (required || this.virtualValue !== '')
        ? valid
        : null;
    }

    /// encapsules v-model from parent to avoid parent property mutation error
    get virtualValue(): unknown {
      return this.value;
    }

    /// Notify the parent the update of the v-model data
    set virtualValue(value: unknown) {
      this.$emit('input', value);
    }
  }

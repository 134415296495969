
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 19th of July 2021
   * Updated on: 18/Aug/2021
   *
   * Summary: AccountsDashboard component
   */
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import { getErrorMessage, paramsSerializer } from '@/utils/Helpers';

  import InputWithIcon from '@/components/common/input-with-icon/InputWithIcon.vue';
  import CreateAccountModal from '@/components/modals/create-account/CreateAccountModal.vue';
  import MessageBoxModal from '@/components/modals/message-box-modal/MessageBoxModal.vue';
  import AccountView, {
    ActionsPayload
  } from '@/components/containers/account-view/AccountView.vue';
  import TreeComponent from '@rdss-mono/ascx-tree';

  import AccountTypes from '@/types/AccountTypes';
  import { AxiosError } from 'axios';

  @Component({
    components: {
      'input-w-icon': InputWithIcon,
      'create-account': CreateAccountModal,
      AccountView,
      TreeComponent,
      MessageBoxModal
    }
  })
  export default class AccountsDashboard extends Vue {
    readonly magnifierIcon = require('*/images/icons/search@2x.png');

    protected search = '';
    protected createError = '';
    protected editError = '';

    protected isEditing = false;
    private editAccountPath = [];
    private clickingNodePath = [];
    private changeAccountForm = false;

    private boxModalMessage = {
      message: '',
      status: '',
      buttonText: '',
      titleTextVariant: ''
    };

    @Getter acmeTree;
    @Getter user;
    @Getter treeAccountDetail;
    @Action populateAcmeTree;
    @Action requestTreeAccountDetail;
    @Action createAccount;
    @Action updateAccount;
    @Action deleteAccount;
    @Action resendAccountUserInvite;
    @Action cleanTreeAccountDetail;
    @Ref() tree: typeof TreeComponent;
    async mounted(): Promise<void> {
      this.cleanTreeAccountDetail();
      const loader = this.$loading.show();
      try {
        await this.populateAcmeTree({
          queryOptions: paramsSerializer({})
        });
      } catch (err) {
        if (
          err.response &&
          err.response.status !== 401 &&
          err.response.data &&
          err.response.data.status_msg_key &&
          err.response.data.status_msg_key !==
            'rest_api.response.error.ACME_ACCOUNT_SUSPENDED'
        ) {
          this.updateMessageBox({
            status: 'label.failed',
            message: getErrorMessage(err),
            buttonText: 'button.return'
          });
          this.$bvModal.show(this.messageBoxModalId);
        }
      } finally {
        loader.hide();
      }
    }

    get createModalName(): string {
      return 'createAccountModal';
    }

    private updateMessageBox({
      message,
      status,
      buttonText,
      titleTextVariant = ''
    }): void {
      this.boxModalMessage.message = message;
      this.boxModalMessage.status = status;
      this.boxModalMessage.buttonText = buttonText;
      this.boxModalMessage.titleTextVariant = titleTextVariant;
    }
    private changeEditFlag() {
      this.changeAccountForm = true;
    }
    protected async accountActions({
      action,
      accountUUID,
      accountType
    }: ActionsPayload): Promise<void> {
      switch (action) {
        case AccountActions.RESTORE:
          this.$bvModal
            .msgBoxConfirm(`${this.$t('label.account-restore.message')}`, {
              title: `${this.$t('label.account-restore')}`,
              size: 'lg',
              buttonSize: 'md',
              okVariant: 'danger',
              okTitle: `${this.$t('button.restore_account')}`,
              cancelTitle: `${this.$t('button.cancel')}`,
              footerClass:
                'p-2 restore-modal-footer justify-content-between form-buttons',
              dialogClass: 'cy-account-restore-modal',
              bodyClass: 'text-center mt-5 mb-5 restore-modal',
              hideHeaderClose: true,
              centered: true
            })
            .then(async (accepted) => {
              if (!accepted) {
                return;
              }
              try {
                await this.updateAccount({
                  accountUUID,
                  account: { status: 'active' },
                  isVNMS: accountType === AccountTypes.VNMS
                });

                this.updateMessageBox({
                  status: 'label.success',
                  message: 'label.account-restore.success',
                  buttonText: 'button.continue'
                });
                this.$bvModal.show(this.messageBoxModalId);
                await this.accountClicked({
                  accountUUID,
                  accountType
                });
              } catch (err) {
                this.updateMessageBox({
                  status: 'label.acme_account_not_found',
                  message: getErrorMessage(err),
                  buttonText: 'button.close',
                  titleTextVariant:
                    err.response.data.status_msg_key ===
                    'rest_api.response.error.ACME_ACCOUNT_NOT_FOUND'
                      ? 'text-danger'
                      : ''
                });
                this.$bvModal.show(this.messageBoxModalId);
              }
            })
            .catch(() => {
              this.updateMessageBox({
                status: 'label.failed',
                message: 'label.account-restore.failed',
                buttonText: 'button.return'
              });
              this.$bvModal.show(this.messageBoxModalId);
            });
          break;
        case AccountActions.SUSPEND:
          this.$bvModal
            .msgBoxConfirm(`${this.$t('label.account-suspend.message')}`, {
              title: `${this.$t('label.account-suspend')}`,
              size: 'lg',
              buttonSize: 'md',
              okVariant: 'danger',
              okTitle: `${this.$t('button.suspend_account')}`,
              cancelTitle: `${this.$t('button.cancel')}`,
              footerClass:
                'p-2 suspend-modal-footer justify-content-between form-buttons',
              dialogClass: 'cy-account-suspend-modal',
              bodyClass: 'text-center mt-5 mb-5 suspend-modal',
              hideHeaderClose: true,
              centered: true
            })
            .then(async (accepted) => {
              if (!accepted) {
                return;
              }
              await this.updateAccount({
                accountUUID,
                account: { status: 'suspended' },
                isVNMS: accountType === AccountTypes.VNMS
              });
              this.updateMessageBox({
                status: 'label.success',
                message: 'label.account-suspend.success',
                buttonText: 'button.continue'
              });
              this.$bvModal.show(this.messageBoxModalId);
              await this.accountClicked({
                accountUUID,
                accountType
              });
            })
            .catch(() => {
              this.updateMessageBox({
                status: 'label.failed',
                message: 'label.account-suspend.failed',
                buttonText: 'button.return'
              });
              this.$bvModal.show(this.messageBoxModalId);
            });
          break;
      }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    deleteAccountAction({ accountUUID, accountType }): void {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('label.delete_account.message')}`, {
          title: `${this.$t('label.delete_account.title')}`,
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: `${this.$t('texts.delete')}`,
          cancelTitle: `${this.$t('button.cancel')}`,
          footerClass:
            'p-2 delete-account-modal-footer justify-content-between form-buttons',
          dialogClass: 'cy-account-restore-modal',
          bodyClass: 'text-center mt-5 mb-5 delete-account-modal',
          hideHeaderClose: true,
          centered: true
        })
        .then((accepted) => {
          if (!accepted) {
            return;
          }
          this.deleteAccount({
            accountUUID,
            isVNMS: accountType === AccountTypes.VNMS
          })
            .then(() => {
              this.updateMessageBox({
                status: 'label.success',
                message: 'label.delete_account_success',
                buttonText: 'button.continue'
              });
              this.$bvModal.show(this.messageBoxModalId);
              this.populateAcmeTree({
                queryOptions: paramsSerializer({})
              }).catch((err) => {
                this.updateMessageBox({
                  status: 'label.failed',
                  message: getErrorMessage(err),
                  buttonText: 'button.return'
                });
                this.$bvModal.show(this.messageBoxModalId);
              });
            })
            .catch((err) => {
              if (
                err.response.data.status_msg_key ===
                'rest_api.response.error.ACME_ACCOUNT_NOT_FOUND'
              ) {
                this.prepareAccountNotFoundModalMessage(err);
              } else {
                this.updateMessageBox({
                  status: 'label.failed',
                  message: 'label.delete_account_failed',
                  buttonText: 'button.return'
                });
              }
              this.$bvModal.show(this.messageBoxModalId);
            });
        });
    }
    async store(
      action: string,
      { account, accountUUID }: EventPayload
    ): Promise<void> {
      const isVNMS = account.account_type === AccountTypes.VNMS;
      switch (action) {
        case 'new':
          try {
            await this.createAccount({
              account,
              isVNMS
            });
            this.createError = '';
            this.$bvModal.hide(this.createModalName);
          } catch (err) {
            this.createError = err.response.data.status_msg_key;
          }
          break;
        case 'edit':
          try {
            await this.updateAccount({
              accountUUID,
              account,
              isVNMS
            });
            this.editError = '';
            this.isEditing = false;
            this.populateAcmeTree({
              queryOptions: paramsSerializer({})
            });
            this.accountClicked({
              accountUUID,
              accountType: account.account_type
            });
          } catch (err) {
            this.editError = err.response.data.status_msg_key;
            if (
              this.editError ===
              'rest_api.response.error.ACME_ACCOUNT_NOT_FOUND'
            ) {
              this.prepareAccountNotFoundModalMessage(err);
              this.$bvModal.show(this.messageBoxModalId);
            }
          }
          break;
      }
    }
    /**
     * Resend Invite
     */
    private async resendInvite({ username, accountType }): Promise<void> {
      const loader = this.$loading.show();
      try {
        await this.resendAccountUserInvite({
          username,
          isVNMS: accountType === AccountTypes.VNMS
        });
        this.updateMessageBox({
          status: 'label.success',
          message: 'label.resend_invite_success',
          buttonText: 'button.continue'
        });
        loader.hide();
      } catch {
        this.updateMessageBox({
          status: 'label.failed',
          message: 'label.resend_invite_failed',
          buttonText: 'button.return'
        });
        loader.hide();
      }
      this.$bvModal.show(this.messageBoxModalId);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async accountClicked({ accountUUID, accountType }): Promise<void> {
      // If the current user´s account is the clicked node (root node)
      if (accountUUID === this.user.account_uuid) {
        this.cleanTreeAccountDetail();
        return;
      }
      const loader = this.$loading.show();
      try {
        await this.requestTreeAccountDetail({
          accountUUID,
          isVNMS: accountType === AccountTypes.VNMS
        });
        if (this.editError) {
          this.editError = '';
        }
      } catch (err) {
        if (
          err.response.data.status_msg_key ===
          'rest_api.response.error.ACME_ACCOUNT_NOT_FOUND'
        ) {
          this.prepareAccountNotFoundModalMessage(err);
        } else {
          this.updateMessageBox({
            status: 'label.failed',
            message: 'label.resend_invite_failed',
            buttonText: 'button.return'
          });
        }
        this.$bvModal.show(this.messageBoxModalId);
      } finally {
        loader.hide();
      }
    }

    public async clickInEditAccount({
      accountUUID,
      accountType,
      selectNode
    }): Promise<void> {
      this.clickingNodePath = selectNode;
      let acceptedCancel = false;
      if (this.isEditing) {
        if (this.changeAccountForm) {
          await this.$bvModal
            .msgBoxConfirm(`${this.$t('accounts.warning.inEdit')}`, {
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'success',
              okTitle: `${this.$t('button.yes')}`,
              cancelTitle: `${this.$t('button.cancel')}`,
              footerClass:
                'p-2 delete-account-modal-footer justify-content-between form-buttons',
              dialogClass: 'cy-account-restore-modal',
              bodyClass: 'text-center mt-5 mb-5 delete-account-modal',
              hideHeaderClose: true,
              centered: true
            })
            .then((accepted) => {
              if (!accepted) {
                acceptedCancel = true;
                this.tree.returnPreNodePath(this.editAccountPath);
                return;
              }
              this.changeAccountForm = false;
              this.changeEditStatus(false);
            });
        } else {
          this.changeAccountForm = false;
          this.changeEditStatus(false);
        }
      }
      if (!acceptedCancel) {
        await this.accountClicked({ accountUUID, accountType });
      }
    }

    private changeEditStatus(status: boolean): void {
      if (status) {
        this.editAccountPath = this.clickingNodePath;
      }
      this.isEditing = status;
    }

    private get messageBoxModalId(): string {
      return 'accounts-dashboard-messageBoxModal';
    }

    private prepareAccountNotFoundModalMessage(error: AxiosError): void {
      this.updateMessageBox({
        status: 'label.acme_account_not_found',
        message: getErrorMessage(error),
        buttonText: 'button.close',
        titleTextVariant: 'text-danger'
      });
    }

    private resetMessageBox(): void {
      this.$bvModal.hide(this.messageBoxModalId);
    }
  }

  enum AccountActions {
    RESTORE = 'restore',
    SUSPEND = 'suspend'
  }

  export interface EventPayload {
    account?: Account;
    accountUUID?: string;
  }

  export interface Account {
    account_type: string;
    name: string;
    info_email_address: string;
    address: string;
    phone: string;
    emergency_phone: string;
    primary_contact: PrimaryContact;
  }

  export interface PrimaryContact {
    name: string;
    email_address: string;
    mobile_phone: string;
    language: string;
    role?: string;
    using_temp_password: boolean;
  }

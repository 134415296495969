
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 22nd of July 2021
   * Updated 30 / Aug / 2021
   *
   * Summary: CreateAccountModal component
   */
  import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
  import { ValidationObserver } from 'vee-validate';

  import ASCXAcmeAccountCreateUpdate from '@rdss-mono/ascx-acme-account-create-update';
  import { supportedLanguages } from '@/utils/Helpers';
  @Component({
    components: {
      ASCXAcmeAccountCreateUpdate,
      ValidationObserver
    }
  })
  export default class CreateAccountModal extends Vue {
    readonly closeIcon = require('*/images/icons/close@2x.png');

    @Prop({ required: true, type: String })
    readonly name: string;

    @Prop({ required: true })
    readonly apiError: string | null;

    private languages = supportedLanguages;

    protected newAccount = {
      account_type: null,
      name: null,
      address: null,
      phone: '',
      emergency_phone: '',
      primary_contact: {
        name: null,
        mfa_method: 'sms',
        email_address: null,
        mobile_phone: '',
        language: 'en',
        role: 'admin'
      }
    };

    isFormInputValid = false;
    validFormInput(data: boolean): void {
      this.isFormInputValid = data;
    }

    @Emit('save')
    // eslint-disable-next-line
    save() {
      // If language is null, s set as english
      this.newAccount.primary_contact.language ??= 'en';
      return this.newAccount;
    }

    @Emit('cleanError')
    cleanError(): void {
      return;
    }

    resetForm(): void {
      this.cleanError();
      this.newAccount = {
        account_type: null,
        name: null,
        address: null,
        phone: '',
        emergency_phone: '',
        primary_contact: {
          mfa_method: 'sms',
          name: null,
          email_address: null,
          mobile_phone: '',
          language: 'en',
          role: 'admin'
        }
      };
    }
  }

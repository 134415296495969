
  import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
  import { ValidationObserver } from 'vee-validate';
  import { isEqual } from 'lodash';
  import ASCXAcmeAccountCreateUpdate from '@rdss-mono/ascx-acme-account-create-update';
  import ASCXAcmeAccountDetails from '@rdss-mono/ascx-acme-account-details';
  import { UserAccount, AccountConfig } from '@/types/UserAccount';
  import { VnmsAccount, VnmsAccountConfig } from '@/types/VnmsAccount';
  import CurrentUser from '@/types/CurrentUser';
  import AccountRoles from '@/types/AccountRoles';
  import AccountTypes from '@/types/AccountTypes';
  import { supportedLanguages } from '@/utils/Helpers';

  @Component({
    components: {
      ASCXAcmeAccountCreateUpdate,
      ValidationObserver,
      ASCXAcmeAccountDetails
    }
  })
  export default class AccountView extends Vue {
    @Prop({ required: true })
    readonly account: UserAccount & VnmsAccount;

    @Prop({ required: true, type: Object as () => CurrentUser })
    readonly currentUser: CurrentUser;

    @Prop({ required: true })
    readonly apiError: string | null;

    @Prop({ required: true, type: Boolean })
    readonly isEditing: boolean;
    private languages = supportedLanguages;
    @Emit('accountActions')
    accountActions(action: string): ActionsPayload {
      return {
        action,
        accountUUID: this.cachedUUID,
        accountType: this.cachedRegistry.account_type
      };
    }

    @Emit('editStatus')
    editStatus(status: boolean): boolean {
      return status;
    }

    @Emit('delete-account')
    deleteAccount(): Record<string, unknown> {
      return {
        accountUUID: this.cachedUUID,
        accountType: this.cachedRegistry.account_type
      };
    }
    @Emit('change-account')
    changeEditFlag(): void {
      return;
    }
    @Emit('cleanError')
    cleanError(): void {
      return;
    }

    @Emit('save')
    save(): SavePayload {
      // If language is null, s set as english
      if (this.currentUser.role == this.managerRole) {
        delete this.cachedRegistry.primary_contact;
        this.cachedRegistry.account_type = this.account.config.account_type;
      } else {
        this.cachedRegistry.primary_contact.language ??= 'en';
      }
      return {
        account: this.cachedRegistry,
        accountUUID: this.cachedUUID
      };
    }
    private translate(key: string): string {
      return this.$t(key).toString();
    }
    protected formValid = false;
    protected cachedRegistry = null;
    protected cachedUUID = null;

    get adminRole(): unknown {
      return AccountRoles.ADMIN;
    }

    get managerRole(): string {
      return AccountRoles.MANAGER;
    }

    get sassOpsType(): unknown {
      return AccountTypes.SASS_OPS;
    }

    get isSuspended(): boolean {
      return this.account.status == AccountStatus.SUSPENDED;
    }

    mounted(): void {
      if (this.account) {
        this.cachedUUID = this.account.account_uuid;
        this.cachedRegistry = {
          ...this.account.config,
          primary_contact: { ...this.account.config.primary_contact }
        };
      }
    }
    @Emit('resend-invite')
    private resendInvite(): Record<string, unknown> {
      return {
        username: this.account.config.primary_contact.email_address,
        accountType: this.account.config.account_type
      };
    }
    public editAccount(): void {
      this.cachedRegistry = {
        ...this.account.config,
        primary_contact: { ...this.account.config.primary_contact }
      };
      this.editStatus(true);
    }

    public discard(): void {
      this.cachedUUID = this.account.account_uuid;
      this.cachedRegistry = {
        ...this.account.config,
        primary_contact: { ...this.account.config.primary_contact }
      };
      this.editStatus(false);
      this.cleanError();
    }

    // eslint-disable-next-line
    public validFormInput(evt: any): void {
      this.formValid = evt;
    }

    @Watch('account')
    private reloadAccount() {
      if (!this.isEditing) {
        this.cachedUUID = this.account.account_uuid;
        this.cachedRegistry = {
          ...this.account.config,
          primary_contact: { ...this.account.config.primary_contact }
        };
      }
    }

    @Watch('cachedRegistry', { deep: true, immediate: false })
    private compareEdit() {
      if (this.isEditing) {
        const origin = this.transForm(this.account.config);
        const accountChange = this.transForm(this.cachedRegistry);
        if (!isEqual(origin, accountChange)) {
          this.changeEditFlag();
        }
      }
    }
    /**
     * Remove spaces from phone numbers for compare
     * **/
    transForm(account) {
      return {
        ...account,
        emergency_phone: account.emergency_phone.replaceAll(' ', ''),
        phone: account.phone.replaceAll(' ', ''),
        primary_contact: {
          ...account.primary_contact,
          mobile_phone: account.primary_contact.mobile_phone.replaceAll(' ', '')
        }
      };
    }
  }

  export interface SavePayload {
    account: AccountConfig | VnmsAccountConfig;
    accountUUID: string;
  }

  export interface ActionsPayload {
    action: string;
    accountType?: string;
    accountUUID?: string;
    userUUID?: string;
  }

  enum AccountStatus {
    ACTIVE = 'active',
    SUSPENDED = 'suspended',
    DELETED = 'deleted',
    CREATED = 'created'
  }
